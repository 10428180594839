<template>
  <div class="data-region">
    <div class="data-region__left">
      <h1 class="data-region__left-page-name">{{ $t('rating-of-regions') }}</h1>
      <div class="data-region__left-page-links">
        <p @click="goRatingRegion('data-region')" :class="{active: route === 'data-region'}">{{ $t('ecophone-rating') }}</p>
        <p @click="goRatingRegion('rating-region')" :class="{active: route === 'rating-region'}">{{ $t('rating-ESG') }}</p>
        <img v-if="route === 'rating-region'" src="@/assets/svg/mobile-rotate.png" alt="" />
      </div>
    </div>
    <RatingRegionContent v-if="this.route === 'rating-region'" />
    <DataRegionContent v-else :edit="edit" @visibleAlert="showAlert=true" />
    <ui-alert v-model="showAlert">
      <h2 class="ui-alert__title">{{ $t('attention') }}</h2>
      <p class="ui-alert__text">{{ getTitle }}</p>
      <ui-button @click="$router.push('/login')" v-if="!isAuth">{{ $t('log-in') }}</ui-button>
      <ui-button @click="$router.push('/profile/edit-profile')" v-else>{{ $t('register') }}</ui-button>
    </ui-alert>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RatingRegionContent from "@/components/template/rating-region/RatingRegionContent.vue";

export default {
  name: "DataRegion",
  components: {
    RatingRegionContent,
    DataRegionContent: () => import('@/components/template/data-region/DataRegionContent'),
    UiButton: () => import('@/components/ui/UiButton'),
    UiAlert: () => import('@/components/ui/UiAlert'),
  },

  data() {
    return {
      showAlert: false,
      edit: false,
      route: 'data-region'
    }
  },

  computed: {
    ...mapGetters(['getUser', 'isAuth']),
    getTitle () {
      return !this.isAuth ? this.$t('log-in-to-view-data-by-region') : this.$t('sign-up-to-view-data-by-region')
    }
  },

  methods: {
    goRatingRegion (route) {
      this.route = route
    }
  },

  mounted () {
    if (this.$route.query.ratingRegion) {
      this.route = 'rating-region'
    }
  }
}
</script>

<style lang="scss" scoped>
.data-region {
  padding: 150px 50px 50px 50px;
  background: #F5F5F5;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 1200px) {
    padding: 104px 20px 50px 20px;
    flex-direction: column;
    gap: 30px;
  }

  &__left {

    display: flex;
    flex-direction: column;
    gap: 48px;

    &-page-name {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #343432;
      white-space: nowrap;
    }

    &-page-links {
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: relative;

      p {
        color: #1B1A1F;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;

        &.active {
          color: #CE2121;
        }
      }

      img {
        position: absolute;
        width: 32px;
        height: 32px;
        right: 0;
        top: 30px;
        display: none;

        @media (max-width: 768px) {
          display: inline-block;
        }
      }
    }

    &-edit {
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      white-space: nowrap;
      cursor: pointer;

      &.active {
        color: #CE2121;
      }
    }
  }
}
</style>
